@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTBook.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTBookOblique.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTMedium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTMediumOblique.otf') format('opentype');
  font-weight: 500;
  font-style: oblique;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTDemi.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTDemiOblique.otf') format('opentype');
  font-weight: 600;
  font-style: oblique;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTBold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Futura PT';
  src: local('Futura PT'),
    url('../fonts/FuturaPT/FuturaPTBoldOblique.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}
